import React, { useEffect, useState } from 'react'
import { Card, Col, Row, message } from 'antd'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { discussion as discussionAPI, user as userApi, rate as rateApi } from '@rap/api'
import styles from './style.module.sass'
import { selectLoginStatus, selectUserInfo } from '../../../redux/userSlice'
import { NotFound } from '../../exception/NotFound'
import Loading from '../../components/Loading'
import ProfileHeader from './components/ProfileHeader'
import RateList from './components/RateList'
import ForumPosts from './components/ForumPosts'
import StatCard from './components/StatCard'

export interface Comment {
  _id: number;
  author: string;
  title: string;
  content: string;
  tags: string[];
  type: 'note' | 'discussion';
  replies: Comment[];
  admin: boolean;
  timestamp: string;
}

const operationTabList = [
  {
    key: 'rates',
    tab: (
      <span>
        Rates
      </span>
    ),
  },{
    key: 'forum',
    tab: (
      <span>
        Forum Posts
      </span>
    ),
  },
]

const UserProfile = () => {
  const params = useParams()
  const loginStatus = useSelector(selectLoginStatus)
  const userInfo = useSelector(selectUserInfo)
  const [verifiedNum, setVerifiedNum] = useState(0)

  const [tabKey, setTabKey] = useState('rates')
  const [user, setUser] = useState<rateauditor.api.user.IUserInfo>()
  const [isLoading, setLoading] = useState(true)
  const [rates, setRates] = useState<rateauditor.api.rate.IRateInfo[]>([])
	const [posts, setPosts] = useState<Comment[]>()

  useEffect(() => {
    if (params.username) {
      if (params.username === userInfo?.username) {
        userApi.getCurrentUserInfo().then((res) => {
          setUser(res.data.user)
          setLoading(false)
        })

        rateApi.getCurrentUserRates().then((res) => {
          setRates(res.data.rates)
          const verifiedRates = res.data.rates.filter((rate: rateauditor.api.rate.IRateInfo) => rate.status === 'verified')
          setVerifiedNum(verifiedRates.length)
        })

				discussionAPI.getUsersDiscussions().then((response) => {
					const updatedDiscussions = response.data.discussion.map((discussion: any) => ({
        		...discussion,
        		timestamp: discussion.created_at, // Map creation_time to timestamp for discussions
        		replies: discussion.replies.map((reply: any) => ({
          		...reply,
          		timestamp: reply.creation_time, // Map created_at to timestamp for replies
        		})),
      		}));
      		setPosts(updatedDiscussions);
    		});
      } else {
        // Currently we only allow account owner view their own profile
        setLoading(false)
        // rateApi.getUserRates(params.username).then((res) => {
        //   setRates(res.data.rates)
        // })
      }
    } else {
      setLoading(false)
    }
  }, [params.username, loginStatus])

  const handleDeleteRate = (rateId: string) => {
    if (loginStatus === 'success' && userInfo) {
      rateApi.deleteRate(rateId).then((res) => {
        if (res.data.status === 'success') {
          message.success('Delete Successful!')
          setRates(rates.filter((value) => value._id !== rateId))
        } else {
          message.warning(res.data.error.message)
        }
      })
    }
  }

  const renderChildrenByTabKey = (tabValue: string) => {
    if (tabValue === 'rates') {
      return <RateList rates={rates} handleDeleteRate={handleDeleteRate} />
    } else if (tabValue === 'forum') {
			return <ForumPosts posts={posts} updatePosts={setPosts}/>
      //return <ForumPosts posts={posts}/>
    }

    return null
  }

  if (isLoading) {
    return <Loading />
  }
  if (!user) {
    return <NotFound />
  }
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <ProfileHeader user={user} />
        </Col>
      </Row>
      <Row gutter={24}>
      <Col span={24}>
          <Card
            style={{ width: '100%' }}
            className={`cardContent ${styles.tabsCard}`}
            bordered={false}
            tabList={operationTabList}
            activeTabKey={tabKey}
            onTabChange={(_tabKey) => {
              setTabKey(_tabKey)
            }}
          >
            {renderChildrenByTabKey(tabKey)}
          </Card>
        {/* <Col xs={24} lg={7}>
          <StatCard verifiedNum={verifiedNum} />
        </Col> */}
      </Col>
      </Row>
    </>
  )
}

export default UserProfile
